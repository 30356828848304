/**
 * nodetype.js
 * Created by PULSARINARA\d.monfort on 25/01/22.
 */

(function ($) {
    "use strict";
    $(document).ready(function () {

        let count = 0;

        let remove = function (id) {
            $(".inout-instance"+id).remove();
            if($(".inout-container").children().length==0 ||
                $(".inout-container").children().children().length==0){
                $(".btn-main-add-inout").show();
            }
        };

        let setEditViewListeners = function () {

            while (true){
                count++;
                let inout_instance = $(".inout-instance"+count);
                if(inout_instance.length==1){
                    let id = count;
                    $(".btn-add-inout"+id).click(function () {
                        addTemplate();
                    });
                    $(".btn-delete-inout"+id).click(function () {
                        remove(id);
                    });
                }else {
                    break;
                }
            }
        };

        let addTemplate = function () {
            count++;
            $(".btn-main-add-inout").hide();

            let template = $(".inout-template").html();
            template = template.replaceAll("%n",count);
            $(".inout-container").append(template);

            let id = count;
            $(".btn-add-inout"+id).click(function () {
                addTemplate();
            });
            $(".btn-delete-inout"+id).click(function () {
                remove(id);
            });

        };

        $(".btn-main-add").click(function () {
            addTemplate();
        });

        if($("#id_nodetype_edit").length == 1) setEditViewListeners();
        if($("#id_nodetype_add").length == 1) addTemplate();

    });
})(jQuery);
