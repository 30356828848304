/**
 * simpleProgram.js
 * Created by PULSARINARA\d.monfort on 23/06/23.
 */

(function ($) {
    "use strict";




    $(document).ready(function () {
        // Si nos encontramos en el programa simple
        if (document.querySelector(".program-simple-rules-container")) {
            // Variables locales
            let countRule = 0; // Cuenta las reglas
            let countActuators = {}; // Cuenta los actuadores [id_regla] = nºActuadores

            /*Función para eliminar la regla*/
            let deleteRule = function (id) {
                $(".value-rule" + id).remove();
            };

            /**
             * Elimina un actuador de la regla.
             * @param id
             * @param actuator_id
             */
            let deleteActuator = function (id, actuator_id){
              $("#actuator-rule"+id+actuator_id).remove();
              // countActuators[id] = countActuators[id] -= 1;
            };

            /**
             * Función que se encarga de actualizar el icono según la selección de las notificaciones
             * @param id
             * @param notify
             */
            let changeNotificationIcon = function (id, notify = true) {
                let icono = $('#btn-add-notification-icon-'+id);

                if (notify){
                    if(icono.hasClass("fa-bell-slash")){
                        icono.removeClass("fa-bell-slash");
                        icono.addClass("fa-bell");
                    }
                }else{
                    if(icono.hasClass("fa-bell")){
                        icono.removeClass("fa-bell");
                        icono.addClass("fa-bell-slash");
                    }
                }
            }

            let clickNone = function (id) {

                return $("#none_noti" + id).on( "click",function () {
                    let checked = $("#none_noti" + id).prop('checked');
                    $("#app_noti" + id).prop('checked', !checked);
                    $("#sms_noti" + id).prop('checked', !checked);
                    $("#email_noti" + id).prop('checked', !checked);

                    changeNotificationIcon(id,false);
                })
            }

            let clickApp = function (id) {

                return $("#app_noti" + id).on( "click",function () {

                    $("#none_noti" + id).prop('checked', false)

                    changeNotificationIcon(id);
                })
            }

            let clickSms = function (id) {

                return $("#sms_noti" + id).on( "click",function () {
                    $("#none_noti" + id).prop('checked', false)

                    changeNotificationIcon(id);
                })
            }

            let clickMail = function (id) {

                return $("#email_noti" + id).on( "click",function () {
                    $("#none_noti" + id).prop('checked', false)

                    changeNotificationIcon(id);
                })
            }

            /**
             * Cambia la seleción de las notificaciones
             * @param id
             */
            let notificationChekboxes = function (id){

                clickNone(id);

                clickApp(id);

                clickSms(id);

                clickMail(id);

            }
            
            let selectNotificationChekboxes = function (id,action) {
                switch (action) {
                    case 'app':
                        clickApp(id).trigger("click");
                        break;

                    case 'mail':
                        clickMail(id).trigger("click");
                        break;

                    case 'sms':
                        clickSms(id).trigger("click");
                        break;

                    default:
                }
            }

            /**
             * Añade un menú modal asociado a una regla.
             * @param id
             */
            let addNotification = function (id){

                let rule = $(".rule-notification-template").html();
                rule = rule.replaceAll("%t", id);
                rule = rule.replaceAll("%n", '1');
                $(".notification-modal").append(rule);

                $('.btn-add-notification-'+id).click(function () {

                    $("#setNotificationOptions"+id).modal('show');

                    notificationChekboxes(id);

                })

                return $("#setNotificationOptions"+id);
            }

            let addActuator = function (id){

                //Se comprueba si existe, si no, lo crea.
                if (!(id in countActuators)){
                    countActuators[id] = 0;
                }else{
                }
                countActuators[id] = countActuators[id] += 1; // Eso le gusta
                let actuator = $(".rule-actuator-template").html();
                let actuator_id = countActuators[id];
                actuator = actuator.replaceAll("%t", id);
                actuator = actuator.replaceAll("%n", actuator_id);
                $(".actuator-rules"+id).append(actuator);

                let btn_delete = $(".btn-delete-actuator" + id+actuator_id);
                let btn_add_actuator = $(".btn-add-actuator-"+id+actuator_id);

                btn_add_actuator.click(function (){
                    addActuator(id);
                });

                btn_delete.click(function () {
                    deleteActuator(id, actuator_id);
                });

                return actuator_id;
            };

            /*Función para añadir una regla*/
            /**
             *
             * Para generar la nueva regla, si viene de editar o no.
             * @param fromButton boolean
             * @returns {*|jQuery|HTMLElement}
             */
            let addRule = function (fromButton) {
                countRule++;

                let id = countRule;
                let rule = $(".value-rule-template").html();
                rule = rule.replaceAll("%t", countRule);
                $(".program-simple-rules-container").append(rule);
                //Se añade el primero
                if (fromButton) {
                    addActuator(id);
                }

                let btn_delete = $(".btn-delete-rule" + id);

                btn_delete.click(function () {
                    deleteRule(id);
                });

                return id;
            };

            //Configuramos el botón de añadir
            if(document.querySelector(".btn-add-rule")){
                $(".btn-add-rule").click(function () {

                    let id = addRule(true);
                    addNotification(id);

                });
            }

            setTimeout(() => {
                if ($("#edit-program-simple-container").length) {
                    let rules = Object.entries(JSON.parse($("#edit-rules").val()))
                    rules.forEach(function (rule) {
                        let ruleId = addRule(false);
                        $('#rule_id'+ruleId).val(rule[1].id);

                        $('#sensor'+ruleId+' option[value="'+rule[1].sensor_id+'"]').prop('selected', true);
                        $('#condition'+ruleId+' option[value="'+rule[1].operator+'"]').prop('selected', true);
                        $('#value_sensor'+ruleId).val(rule[1].value);

                        let actuadores =  Object.entries(rule[1].programActuators);

                        actuadores.forEach(function (actuador) {
                            addActuator(ruleId);
                            let actuator_id = countActuators[ruleId];
                            $('#actuator_uid'+ruleId+actuator_id).val(actuador[1].uid);
                            $('#actuator'+ruleId+actuator_id+' option[value="'+actuador[1].actuator_uid+'"]').prop('selected', true);
                            $('#actuator_state'+ruleId+actuator_id).val(actuador[1].value);
                        });

                        // let notification =  Object.entries(rule[1].notification);
                        let notification =  rule[1].notification;

                        //Añadir notificación.
                        let alert = addNotification(ruleId);

                        $.each(notification, function (name,value) {

                            if (name === 'id'){
                                $('#notification_id_'+ruleId).val(value);
                            }
                            if (name === 'msg'){
                                $('#msg'+ruleId).val(value)
                            }
                            if (value === '1'){
                                selectNotificationChekboxes(ruleId,name);
                            }
                            if (name === 'contacts'){
                                $.each(value, function (key,contact_id) {
                                    console.log(key);
                                    console.log($('#contact_relation_'+ruleId+contact_id));
                                    $('#contact_relation_'+ruleId+contact_id).val(key);
                                    $('#contact_send'+ruleId+contact_id).attr('checked', true);
                                });
                            }
                        });


                    })
                }
            }, 600);
        }
    });


    // Ajax executed on user change
    $(document).ready(function () {
        let loadProgramItems = function (item) {

            let data = {
                item_id: item.val(),
            } ;

            if ($('#view').val() === 'edit'){
                data['edit'] = $('#uid').val();
            }

            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-programitems'),
                data    : data,
                success : function (data) {

                    $.each(data, function (name) {
                        if ($("#" + name).length) {

                            $("#" + name).find('option').remove();

                            $.each(this, function (i, e) {

                                if (e.selected === 'true'){
                                    $("#" + name).append('<option value="' + e.uid + '" selected>' + e.name + '</option>');
                                }else{
                                    $("#" + name).append('<option value="' + e.uid + '">' + e.name + '</option>');
                                }
                            });

                            $("#" + name).change();
                        }

                        if ($(".address_list").length) {
                            $(".program-simple-rules-container").empty();
                            $(".contacts_list").empty();
                            let contacts = data["contacts"];
                            $.each(contacts, function (name) {
                                let contact = contacts[name];

                                $(".contacts_list").append('<li><input type="hidden" id="contact_relation_%t'+ contact.id +'" name="notification[contact_old][%t]['+ contact.id +']" value=""><input type="checkbox" id="contact_send%t' + contact.id + '" name="notification[contact_send][%t]['+ contact.id +']"><label for="contact_send%t' + contact.id + '">&nbsp;&nbsp;  ' + contact.name + ' | ' + contact.tlf + '&nbsp; |&nbsp;  ' + contact.email + '</label></li>')

                            });
                        }
                    });



                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR -> url-programItems', jqXHR);
                },
                complete: function (data) {
                    setTimeout(() => {
                        $('.selectpicker').selectpicker('refresh');
                        $('.selectpicker').selectpicker('render');
                    }, 200);
                },

            })
        }

        if ($('.select_programItem').length) {
            $('.select_programItem').on('change', function () {
                loadProgramItems($(this));
            });

            /*
               * Función utilizada para los edit, para no sobreescribir los datos que ya existen
               * Añandir lo siguiente en el 'editView' dentro del form:
               *   <input type="hidden" id="view" value="edit">

               * Utilizado en:
               * simpleprogram.js
               * loadItems.js
               *
               * */
            $('.select_programItem').change();
            if ($('#view').val() !== 'edit') {
            }

        }
    });


    // Ajax executed on installation change
    $(document).ready(function () {
        let loadElements = function (item) {

            $.ajax({
                type    : "POST",
                dataType: "json",
                url     : item.data('url-element'),
                data    : {
                    item_id: item.val(),
                },
                success : function (data) {
                    // vaciamos los turnos
                    if ($(".element-conditions").length) {

                        $(".element-conditions").find('option').remove();
                        let elements = data["sensors"];

                        $.each(elements, function (name) {

                            let element = elements[name];
                            $(".element-conditions").append('<option value="' + element["id"] + '">' + element["name"] + '</option>');

                        });
                    }
                    if ($(".element-actuators").length) {
                        $(".element-actuators").find('option').remove();
                        let elements = data["actuators"];

                        $.each(elements, function (name) {

                            let element = elements[name];
                            $(".element-actuators").append('<option value="' + element["uid"] + '">' + element["name"] + '</option>');

                        });
                    }

                },
                error   : function (jqXHR, textStatus, errorThrown) {
                    console.log('[filterById] ERROR -> url-element', jqXHR);
                }

            })
        };

        if ($('.select-elements').length) {
            $('.select-elements').on('change', function () {
                loadElements($(this));
            });

        }

    });
    // Programa siempre activo.

    $(document).ready(function () {
        if ($('#working_dates_switch')){
            if ($('.working_dates_switch').is(':checked')){
                $('#working_dates').hide();
            }else{
                $('#working_dates').show();
            }

            $('.working_dates_switch').on('click', function () {
                if ($('.working_dates_switch').is(':checked')){
                    $('#working_dates').hide();
                }else{
                    $('#working_dates').show();

                }
            });
        }
    });

}(jQuery));