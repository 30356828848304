/**
 * installation.js
 */
(function ($) {
    "use strict";

    $(document).ready(function () {
        if ($(".design_container").length) {
            var replaceLink = function (id, v) {
                document.getElementById('link' + id).href = "https://beta.agustina.hidro.agroxcontrol.com/es/design/info/" + v;
            };
        }

    });

    //Encargado de añadir los diseños a la instalación
    $(document).ready(function () {

        let count = 0;

        let replaceLink = function (id, v) {
            document.getElementById('link' + id).href = "https://beta.agustina.hidro.agroxcontrol.com/es/design/info/" + v;
        };
        let deleteDesign = function (id) {
            $(".added-template" + id).remove();
        };

        let showDesign = function (id) {
            let select = $("#design_id" + id);
            let base_url = select.attr("data-urlbase");
            let design_id = $("#design_id" + id + " :selected").val();

            let url_info_desgin = base_url + "/design/info/" + design_id;
            // document.location.replace(url_info_desgin);
            // return;
        };

        let addDesign = function (id) {
            let template = $(".desing_template").html();
            template = template.replaceAll("%n", id);

            $(".design_container").append(template);

            $(".btn-delete-design" + id).click(function () {
                deleteDesign(id);
            });

            $(".btn-show-design" + id).click(function () {
                showDesign(id);
            });
        }

        $(".btn-add-design").click(function () {
            count++;
            addDesign(count);

        });

        if ($('#view').val() === 'edit') {
            setTimeout(() => {
                let desings =  Object.entries(JSON.parse($("#edit-designs").val()));
                desings.forEach(function (design) {

                    count++;
                    addDesign(count);
                    $('#design_id' + count + ' option[value="' + design[1].uid + '"]').prop('selected', true);

                    replaceLink(count, design[1].uid);
                });
            }, 200);
        }

    });

    $(document).ready(function () {

        if ($('.load_designs').length) {
            let loadDesigns = function (item) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: item.data('url'),
                    data: {
                        item_id: item.val(),
                    },
                    success: function (data) {

                        if ($(".designs").length) {

                            $(".designs").find('option').remove();
                            let designs = data["designs"];
                            $.each(designs, function (name) {
                                let design = designs[name];
                                $(".designs").append('<option value="' + design["uid"] + '">' + design["name"] + '</option>');
                            });
                        }

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('[filterById] ERROR installation.js -> url', jqXHR);

                    }
                });
            };


            $('.load_designs').change(function () {
                loadDesigns($(this));

            })
            $('.load_designs').change();
        }
    });

    $(document).ready(function () {

        if ($('.user_manage_installations').length) {

            let loadInstallations = function (item) {
                $.ajax({
                    type: "POST",
                    dataType: "json",
                    url: item.data('url'),
                    data: {
                        item_id: item.val(),
                    },
                    success: function (data) {

                        if ($(".installations_manage").length) {

                            // $(".installations_manage").find('option').remove();
                            $(".installations_manage").empty();
                            let installations = data["installations"];
                            $.each(installations, function (name) {
                                let installation = installations[name];
                                $(".installations_manage").append('<option value="' + installation["uid"] + '">' + installation["name"] + '</option>');
                            });
                        }

                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('[filterById] ERROR installation.js -> url', jqXHR);

                    }
                });
            };


            $('.user_manage_installations').change(function () {
                loadInstallations($(this));
            });
            $('.user_manage_installations').change();
        }


        if ($('.installations_manage').length) {

            //Se usa el timeout para que se cargue bien la instalación
            setTimeout(() => {
                let loadDataInstallations = function (item) {

                    $.ajax({
                        type: "POST",
                        dataType: "json",
                        url: item.data('url'),
                        data: {
                            item_id: item.val()
                        },
                        success: function (data) {

                            console.log(data);
                            //Cargar los programas

                            //Cargar los sectores


                        },
                        error: function (jqXHR, textStatus, errorThrown) {
                            console.log('[filterById] ERROR installation.js -> url', jqXHR);

                        }
                    });
                };


                $('.installations_manage').change(function () {
                    loadDataInstallations($(this));
                });
                $('.installations_manage').change();

            }, 100);
        }
    });


})(jQuery);

